import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';
import { Create, SimpleForm, TextInput, DateInput, SelectInput, required } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { FileInput, FileField } from 'react-admin';

export const FirmwareList = () => (
    <List hasCreate>
        <Datagrid>
            <TextField label="Model" source="model" />
            <TextField label="FW Name" source="fw_name" />
            <TextField label="FW Type" source="fw_type" />
            <TextField label="Version" source="version" />
            <TextField label="MD5 Checksum" source="md5" />
            <DateField label="Date" source="upload_ts" showTime />
        </Datagrid>
    </List>
);

const fw_type_choices = [
    { id: 'fw', name: 'Dashcam Firmware' },
    { id: 'ai_fw', name: 'AI Firmware' },
    { id: 'modem_fw', name: 'Modem Firmware' },
]

const fw_model_choices = [
    { id: 'T5304V2', name: 'Dashcam T5304V2' },
    { id: 'DC082', name: 'Dashcam DC082' },
    { id: 'T8112B', name: 'Dashcam ET12 (T8112B)' },
]

const ai_fw_model_choices = [
    { id: 'T5304V2-AI', name: 'T5304V2 AI firmware' },
    { id: 'DC082-AI', name: 'DC082 AI firmware' },
    { id: 'T8112B-AI', name: 'T8112B AI firmware' },
]

const modem_fw_model_choices = [
    { id: 'EG25G-C1', name: 'Modem EG25G-C1' },
]

/* Validator functions should return undefined if the value is valid, or a string describing the error if it’s invalid. */
function validate_no_space (value) {
    if (value && value.indexOf(' ') >= 0) {
        return 'Must not contains white space';
    }
    return undefined;
}

const FWModelInput = props => {
    const fw_type = useWatch({ name: 'fw_type' });
    if (fw_type === 'fw') {
        return (
            <SelectInput source="model"
                choices={fw_model_choices}
                {...props}
            />)
    } else if (fw_type === 'ai_fw') {
        return (
            <SelectInput source="model"
                choices={ai_fw_model_choices}
                {...props}
            />)
    } else if (fw_type === 'modem_fw') {
        return (
            <SelectInput source="model"
                choices={modem_fw_model_choices}
                {...props}
            />)
    }
};

export const FirmwareCreate = props => (
    <Create>
        <SimpleForm>
            <TextInput source="fw_name" validate={[required(), validate_no_space()]} />
            <TextInput source="version" validate={[required(), validate_no_space()]} />
            <SelectInput source="fw_type"  choices={fw_type_choices} validate={required()} />
            <FWModelInput validate={required()} />
            <FileInput source="fw_file">
                <FileField source="fw_file" title="Firmware File" />
            </FileInput>
        </SimpleForm>
    </Create>
);

